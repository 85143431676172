// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

// Components
import { device } from '../../../device';

// Styles
const Container = styled.section`
  grid-area: OUT;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.tablet} {
    border-top: ${props => props.boxBottom && '1px solid #c6c8cc'};
    padding-top: ${props => props.boxBottom && '30px'};
  }
`;

const AllServices = styled.div`
  padding: 1.875rem;
  margin-bottom: ${props =>
    props.Outdor ? '4.375rem' : props.AnatomyOrCIGI ? '4.375rem' : '3rem'};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 0.5rem;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: ${props => (props.boxBottom ? 'none' : 'flex')};
    margin-bottom: 1.875rem;
  }

  @media ${device.tablet} {
    display: ${props =>
      props.Outdor ? 'flex' : props.boxBottom ? 'flex' : 'none'};
    margin-bottom: ${props =>
      props.marginBottomTablet 
        ? props.marginBottomTablet 
        :  props.Outdor
        ? '1.875rem'
        : props.AnatomyOrCIGI
        ? '1.875rem'
        : '0.625rem'};
  }

  ${props => props.Covid19 && css`
    margin-bottom: 1.875rem;
  `}
`;

const ContainerText = styled.div`
  justify-content: ${props => !props.isNotItaim && 'center'};

  > p {
    display: flex;
    flex-direction: ${props =>
      !props.Outdor && props.isNotItaim && !props.AnatomyOrCIGI
        ? 'column'
        : 'row'};
    font-size: 1.5rem;

    @media ${device.laptop} {
      width: ${props => (props.little ? '48%' : '100%')};
      font-size: 1.125rem;
      flex-direction: column;
    }

    @media ${device.tablet} {
      width: ${props => (props.little ? '48%' : '17.5rem')};
    }

    > b {
      margin-left: ${props =>
        !props.isNotItaim || props.Outdor
          ? '.3rem'
          : props.AnatomyOrCIGI && '.3rem'};
      @media ${device.tablet} {
        display: contents;
      }
    }
  }
`;

const ButtonAllServices = styled.div`
  padding: 0.875rem 1.25rem;
  margin-top: 1.875rem;
  font-size: 0.875rem;
  color: #fff;
  border: none;
  border-radius: 1.438rem;
  background: #45a7df;
  transition: 0.6s background;
  margin: 1.875rem 0 0;

  :hover {
    background: #1d52a3;
  }

  @media ${device.laptop} {
    width: ${props => props.isProntoAtendimento && '280px'};
  }

  @media ${device.tablet} {
    padding: 1rem;
    font-size: 0.875rem;
    border-radius: 6.25rem;
  }

  @media ${device.mobile320} {
    width: ${props => props.isProntoAtendimento && '230px'};
  }
`;

const OutdoorServices = ({
  pageName,
  little,
  boxBottom,
  Outdor,
  url,
  isProntoAtendimento,
  AnatomyOrCIGI,
  Covid19,
  marginBottomTablet
}) => {
  const isNotItaim = pageName !== 'Itaim';
  return (
    <Container little={little} boxBottom={boxBottom}>
      <AllServices
        little={little}
        boxBottom={boxBottom}
        Outdor={Outdor}
        AnatomyOrCIGI={AnatomyOrCIGI}
        Covid19={Covid19}
        marginBottomTablet={marginBottomTablet}
      >
        <ContainerText
          isNotItaim={isNotItaim}
          Outdor={Outdor}
          boxBottom={boxBottom}
          AnatomyOrCIGI={AnatomyOrCIGI}
        >
          <p>
            {Covid19 ? 'Confira aqui o que já falamos sobre a pandemia' : Outdor
              ? 'Estamos prontos para atender você na'
              : AnatomyOrCIGI
              ? 'Conheça mais serviços do'
              : 'Estamos prontos para atender você no'}
            <b>
              {Outdor && isNotItaim
                ? ' Unidade'
                : AnatomyOrCIGI
                ? ' '
                : pageName !== 'Itaim' && ' Hospital '}{' '}
              {Outdor ? 'Hospital' : AnatomyOrCIGI ? ' ' : 'Sírio-Libanês'}{' '}
              {pageName === 'Itaim' ? 'Itaim' : pageName}
            </b>
          </p>
        </ContainerText>
        <Link to={Outdor ? url : AnatomyOrCIGI ? url : 'detalhes'}>
          <ButtonAllServices
            Outdor={Outdor}
            isProntoAtendimento={isProntoAtendimento}
          >
            {Covid19 ? 'Saiba mais sobre o Covid-19' : Outdor ? 'Saiba mais' : 'Veja todos os nossos serviços'}
          </ButtonAllServices>
        </Link>
      </AllServices>
    </Container>
  );
};

export default OutdoorServices;
