// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import TextSection from 'components/units/unitPages/details/TextSection';
import WhyChooseOurCDI from 'components/cdi/WhyChooseOurCDI';
import MainServices from 'components/promptService/mainServices';
import PromptServiceContent from 'components/promptService/promptServiceContent';

// Image
import firstBlob from 'images/blobs/Slime_Purple.webp';
import secBlob from 'images/blobs/Slime_Pink.webp';
import Outdoor from 'components/units/unitPages/unit/outdoorService';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    border-bottom: ${props => props.notPaddingTop && '1px solid #C6C8CC'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '31rem'};
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }
`;

const GridMainServices = styled.div`
  margin: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    border-radius: 8px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'}>
      <FeaturedMarketing
        markdown={getMarkdown(page, 'ckmm59g2ottuk0b772s4rorm1', true)}
        breadCrumb={getMarkdown(page, 'ckmm4ysl4tw3w0a65bdlqej07', true)}
        asset={getMarkdown(page, 'ckmm59g2ottuk0b772s4rorm1', false, true)}
        blobs={{ firstBlob, secBlob }}
        isPatologica
        firstBlobTransformDesk="translate(166%,-20%) rotate(181deg)"
        firstBlobTransformDeskLarge="translate(166%,-20%) rotate(181deg)"
        firstBlobTransformLap="translate(217%,-17%) rotate(179deg)"
        firstBlobTransformMob="translate(176%,-15%) rotate(179deg)"
        secBlobTransformDesk="translate(-141%,79%) rotate(189deg)"
        secBlobTransformDeskLarge="translate(-141%,79%) rotate(189deg)"
        secBlobTransformLap="translate(-192%,96%) rotate(-16deg)"
        secBlobTransformMob="translate(-148%,152%) rotate(191deg)"
        containerMarginBottomDesk
        titleWidthLap="30rem"
        boxInfoTextWidthDesk="28.125rem"
        boxContentPaddingBottomDesk="6.25rem"
        boxInfoParagraphImgTransformDesk="translate(0, 80%)"
        boxInfoParagraphImgWidth="unset"
        boxInfoParagraphImgHeight="unset"
      />
    </Part>
    <Part gridColumn="2 / -2" width="100%" isDisplay="flex" notPaddingTop>
      <TextSection //Sobre
        markdown={getMarkdown(page, 'ckmm69y480c8o0d21p8tpt6u4', true)}
        isAbout
        isShowing
        isCDICardio
        isPatologica
        isBackground
        doNotIgnoreTagBr
      />
      <TextSection
        markdown={getMarkdown(page, 'ckmm75ciob4v90b74cw5yf6yp', true)}
        fragments={getMarkdown(page, 'ckmm75ciob4v90b74cw5yf6yp')}
        isAbout
        isShowing
        isPatologica
        isMarginBottomTextH2
        doNotIgnoreTagBr
        marginLeftContainerMobile="3%"
        containerColorEmDesk="#231f20"
        isAddressAndTimes
      />
    </Part>
    <Part gridColumn={'2 / -2'}>
      <WhyChooseOurCDI
        markdown={getMarkdown(page, 'ckmm5yad4091x0d16ucehk99w', true)}
        fragments={getMarkdown(page, 'ckmm5yad4091x0d16ucehk99w')}
        notPaddingLeftLiMobile
        isCDIBrasilia
        isPatologica
      />
    </Part>
    <GridMainServices>
      <Part gridColumn={'2 / -2'}>
        <MainServices
          markdown={getMarkdown(page, 'ckmm7gdw8uwo20a65dldagcnw', true)}
          isPatologica
        />
      </Part>
    </GridMainServices>
    <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmmattt4vtcw0a65lusoa3ty', true)}
        fragments={getMarkdown(page, 'ckmmattt4vtcw0a65lusoa3ty')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isPatologica
      />
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmmbh0oovyn90a651dgm0q4o', true)}
        fragments={getMarkdown(page, 'ckmmbh0oovyn90a651dgm0q4o')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
      />
    </Part>
    <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmmaxd4gvv2c0a65cbgmxdjq', true)}
        fragments={getMarkdown(page, 'ckmmaxd4gvv2c0a65cbgmxdjq')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isFacilities
        isPatologica
      />
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmmc0ezkw4w90a657qrm2nab', true)}
        fragments={getMarkdown(page, 'ckmmc0ezkw4w90a657qrm2nab')}
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
      />
    </Part>
    <Part gridColumn={'2 / -2'} borderBottom largeTitle notLeft>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmnl7y1cjwgv0b74v3bqduxh', true)}
        fragments={getMarkdown(page, 'ckmnl7y1cjwgv0b74v3bqduxh')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isExam
        isFacilities
        isPatologica
      />
    </Part>
    <Part
      gridColumn={'2 / -2'}
      borderBottom
      largeTitle
      notPadding
      notLeft
      shortPadding
    >
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmm7ph200tov0d21ipmefofo', true)}
        fragments={getMarkdown(page, 'ckmm7ph200tov0d21ipmefofo')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isFacilities
        isPatologica
      />
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmme6dbcwohl0b77gk33rk5x', true)}
        fragments={getMarkdown(page, 'ckmme6dbcwohl0b77gk33rk5x')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
        Tests
      />
    </Part>
    <Part gridColumn={'2 / -2'} notLeft borderBottom>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmmasr88vsrt0a65a38vrc6m', true)}
        fragments={getMarkdown(page, 'ckmmasr88vsrt0a65a38vrc6m')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isFacilities
        isPatologica
      />
    </Part>
    <Part gridColumn={'2 / -2'} paddingTop>
      <Outdoor
        pageName="Centro de Diagnósticos"
        url="/centro-de-diagnosticos"
        boxBottom
        AnatomyOrCIGI
      />
    </Part>
  </GridContainer>
);
const AnatomiaPatologica = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckmm59g2ottuk0b772s4rorm1', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckmm4wtlktxnw0c78ehkbqkn7" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <AnatomiaPatologica page={response.gcms.site.pages} />;
      }}
    />
  );
};
